export default {
  'VideoWidget': () => import('./Video.vue'),
  'ZoomImage': () => import('./ZoomImage.vue'),
  'GalleryMedium': () => import('./GalleryMedium.vue'),
  'GallerySmall': () => import('./GallerySmall.vue'),
  'ModalDefault': () => import('./modals/ModalDefault'),
  'ModalSuccess': () => import('./modals/ModalSuccess'),
  'ButtonModal': () => import('./buttons/ButtonModal'),
  'CartItemsCount': () => import('./data/CartItemsCount'),
  'ButtonCartAddItem': () => import('./buttons/ButtonCartAddItem'),
  'MapObjects': () => import('./MapObjects'),
  'ContFixed': () => import('./ContFixed'),
  'VistavkaBunner': () => import('./vistavka/VistavkaBunner'),
  'Offer': () => import('./Form/Offer'),
  'MapHouses': () => import('./MapHouses'),
  'ArchChart': () => import('./ArchChart'),
  'StYearChart': () => import('./StYearChart'),
  'BrandChart': () => import('./BrandChart'),
  'MetrikaChart': () => import('./MetrikaChart'),
  'AgeChart': () => import('./AgeChart'),
  'Chart': () => import('./Chart'),
  'Subscribe': () => import('./modals/Subscribe'),
}
